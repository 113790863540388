import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {map, Observable} from 'rxjs';
import {DropdownOption} from '../../models/utility/option.model';
import {
  Order, OrderRejectionReason,
  OrderDto,
  OrderForm,
  OrderIntent,
  OrderStatus,
  OrderType
} from '../../models/order.model';
import {StatusStyle} from '../../models/status.model';
import {Filter} from '@shared/models/utility/filter';
import {Page} from '@shared/models/utility/page.model';
import {HttpParamBuilder} from '@core/utils/http-param-builder';
import {convertArrayOfFiltersToObject} from '@core/utils/filters';
import {OrderListOrdering} from '@modules/dashboard/modules/order/models/order-filters.model';
import {Event, EventDto} from '@shared/models/shipment-event.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private url = '/api/order';

  constructor(private _httpClient: HttpClient) {
  }

  public fetchOrderTypes(): Array<DropdownOption<OrderType>> {
    return [
      {label: 'B2B', value: OrderType.B2B},
      {label: 'Miscellaneous', value: OrderType.MISC},
      {label: 'IWT', value: OrderType.IWT},
      {label: 'Procurement', value: OrderType.PROCUREMENT}
    ];
  }

  public fetchOrderRejectionReasons(): Array<DropdownOption<OrderRejectionReason>> {
    return [
      {label: 'Order Exceed Transport Limitation', value: OrderRejectionReason.EXCEED_TRANSPORT_LIMITATIONS},
      {label: 'Order Packed Incorrectly', value: OrderRejectionReason.INCORRECT_PACKING},
      {label: 'Packing List Missing', value: OrderRejectionReason.PACKING_LIST_MISSING},
      {label: 'Packing List Incorrect', value: OrderRejectionReason.PACKING_LIST_INCORRECT},
      {label: 'Missing Unit Values', value: OrderRejectionReason.MISSING_UNIT_VALUES},
      {label: 'Incorrect Incoterms', value: OrderRejectionReason.INCORRECT_INCOTERMS},
      {label: 'Missing Contact Details', value: OrderRejectionReason.MISSING_CONTACT_DETAILS},
      {label: 'Incorrect Collection or Delivery Address', value: OrderRejectionReason.INCORRECT_COLLECTION_OR_DELIVERY_ADDRESS},
      {label: 'Cancellation Requested By Customer', value: OrderRejectionReason.CANCELLATION_REQUESTED_BY_CUSTOMER},
      {label: 'Cancellation Requested By THG', value: OrderRejectionReason.CANCELLATION_REQUESTED_BY_THG},
      {label: 'Incorrect IWT Transport Mode', value: OrderRejectionReason.INCORRECT_IWT_TRANSPORT_MODE},
      {label: 'Missing Collection Or Delivery Reference', value: OrderRejectionReason.MISSING_COLLECTION_OR_DELIVERY_REFERENCE}
    ];
  }

  public fetchIntentTypes(orderIntent?: OrderIntent): Array<DropdownOption<OrderIntent>> {
    const options: Array<DropdownOption<OrderIntent>> = [
      {label: 'Shipping', value: OrderIntent.SHIPPING},
      {label: 'Quote (without shipping)', value: OrderIntent.QUOTE_ONLY},
      {label: 'Document Only (without shipping)', value: OrderIntent.DOCUMENT_ONLY},
      {label: 'Customs (without shipping)', value: OrderIntent.CUSTOMS}
    ];

    if (orderIntent === OrderIntent.WAREHOUSE_PLANNED) {
      options.push({label: 'Warehouse Planned', value: OrderIntent.WAREHOUSE_PLANNED});
    }

    return options;
  }

  private getOrderStatuses(): Map<OrderStatus, StatusStyle> {
    return new Map<OrderStatus, StatusStyle>([
      [OrderStatus.CANCELLED, {name: 'Cancelled', severity: 'danger'}],
      [OrderStatus.QUOTE_PENDING, {name: 'Quote Pending', severity: 'warning'}],
      [OrderStatus.QUOTE_ASSIGNED, {name: 'Quote Assigned', severity: 'info'}],
      [OrderStatus.QUOTED, {name: 'Quoted (Closed)', severity: 'success'}],
      [OrderStatus.DOCUMENT_ONLY_PENDING, {name: 'Document Only Pending', severity: 'warning'}],
      [OrderStatus.DOCUMENT_ONLY_ASSIGNED, {name: 'Document Only Assigned', severity: 'info'}],
      [OrderStatus.DOCUMENT_ONLY_COMPLETED, {name: 'Document Only Completed (Closed)', severity: 'success'}],
      [OrderStatus.CUSTOMS_PENDING, {name: 'Customs Pending', severity: 'warning'}],
      [OrderStatus.CUSTOMS_ASSIGNED, {name: 'Customs Assigned', severity: 'info'}],
      [OrderStatus.CUSTOMS_COMPLETED, {name: 'Customs Completed (Closed)', severity: 'success'}],
      [OrderStatus.UNSCHEDULED, {name: 'Unscheduled', severity: 'warning'}],
      [OrderStatus.SCHEDULED, {name: 'Scheduled', severity: 'success'}]
    ]);
  }

  public fetchOrderStatusStyling(status: OrderStatus): StatusStyle {
    const map = this.getOrderStatuses();
    return map.has(status) ? map.get(status)! : {name: status, severity: 'info'};
  }

  public fetchOrderStatus(): Array<DropdownOption<OrderStatus>> {
    return Array.from(this.getOrderStatuses(), ([status, style]) => ({label: style.name, value: status}));
  }

  fetchOrderListOrdering(): Array<DropdownOption<OrderListOrdering>> {
    return [
      {label: 'Created Date', value: OrderListOrdering.CREATED_DATE},
      {label: 'Ranking', value: OrderListOrdering.RANKING},
      {label: 'Updated Date', value: OrderListOrdering.UPDATED_DATE}
    ];
  }

  public createOrder(orderForm: OrderForm): Observable<Order> {
    return this._httpClient.post<Order>(this.url, orderForm);
  }

  public allocateOrderToShipment(orderId: string, shipmentId: string): Observable<Order> {
    return this._httpClient.put<Order>(`${this.url}/${orderId}/allocate/${shipmentId}`, {});
  }

  public updateOrder(id: string, orderForm: OrderForm): Observable<Order> {
    return this._httpClient.put<Order>(`${this.url}/${id}`, orderForm);
  }

  public reopenOrder(id: string): Observable<void> {
    return this._httpClient.put<void>(`${this.url}/${id}/reopen`, {});
  }

  public rejectOrder(id: string, message: string): Observable<void> {
    return this._httpClient.put<void>(`${this.url}/${id}/reject`, {'message': message});
  }

  public fetchOrder(orderNumber: string): Observable<Order> {
    return this._httpClient.get<OrderDto>(`${this.url}/${orderNumber}`).pipe(map((dto) => ({
      ...dto,
      requestedCollectionDate: DateTime.fromISO(dto.requestedCollectionDate),
      requestedDeliveryDate: DateTime.fromISO(dto.requestedDeliveryDate),
      actionByDate: DateTime.fromISO(dto.actionByDate),
      createdDate: DateTime.fromISO(dto.createdDate),
      rejectionDate: DateTime.fromISO(dto.rejectionDate),
      reopenDate: dto.reopenDate ? DateTime.fromISO(dto.reopenDate) : undefined
    })));
  }

  public fetchOrderList(
    offset: number,
    size: number,
    filters: Array<Filter>,
    search: string | null
  ): Observable<Page<Order>> {
    search = (search ? search.trim() : null);
    const params: HttpParams = HttpParamBuilder({offset, size, ...convertArrayOfFiltersToObject(filters), search});
    return this._httpClient.get<Page<OrderDto>>(`${this.url}/list`, {params}).pipe(map((dto: Page<OrderDto>) => ({
      ...dto,
      data: dto.data.map((orderDto) => ({
        ...orderDto,
        requestedCollectionDate: DateTime.fromISO(orderDto.requestedCollectionDate),
        requestedDeliveryDate: DateTime.fromISO(orderDto.requestedDeliveryDate),
        actionByDate: DateTime.fromISO(orderDto.actionByDate),
        createdDate: DateTime.fromISO(orderDto.createdDate),
        rejectionDate: DateTime.fromISO(orderDto.rejectionDate),
        reopenDate: orderDto.reopenDate ? DateTime.fromISO(orderDto.reopenDate) : undefined
      }))
    })));
  }

  public fetchAllOrdersForAShipment(shipmentId: string): Observable<Array<Order>> {
    const params: HttpParams = HttpParamBuilder({shipmentId});
    return this._httpClient.get<Array<OrderDto>>(`${this.url}/list/shipment`, {params}).pipe(map((dto: Array<OrderDto>) => {
      return dto.map((orderDto) => ({
        ...orderDto,
        requestedCollectionDate: DateTime.fromISO(orderDto.requestedCollectionDate),
        requestedDeliveryDate: DateTime.fromISO(orderDto.requestedDeliveryDate),
        actionByDate: DateTime.fromISO(orderDto.actionByDate),
        createdDate: DateTime.fromISO(orderDto.createdDate),
        rejectionDate: DateTime.fromISO(orderDto.rejectionDate),
        reopenDate: orderDto.reopenDate ? DateTime.fromISO(orderDto.reopenDate) : undefined
      }));
    }));
  }

  public deallocateOrderFromShipment(orderId: string, shipmentId: string): Observable<void> {
    return this._httpClient.put<void>(`${this.url}/${orderId}/deallocate/${shipmentId}`, {});
  }

  public fetchOrderEvents(id: string): Observable<Array<Event>> {
    return this._httpClient.get<Array<EventDto>>(`${this.url}/${id}/timeline`).pipe(
      map((events) => events.map((event) => ({
        ...event,
        event: JSON.parse(event.event.json),
        createdDate: DateTime.fromISO(event.createdDate)
      }))));
  }

}
