import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Button} from 'primeng/button';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html'
})
export class CommentSectionComponent {

  @Input({required: true}) comment = '';
  @Output() commentChange = new EventEmitter<string>();
  @Input() placeholder = '';
  @Input({required: true}) onSave!: () => void;
  @Input({required: true}) onCancel!: () => void;

}
