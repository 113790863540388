import {DateTime} from 'luxon';
import {Address, ContactInformation} from './address.model';
import {Cost} from './cost.model';
import {Incoterm} from './incoterms.model';
import {MeasurementSystem} from './measurement-system.model';
import {PackageStructure} from './package.model';
import {Organisation, User} from './user.model';
import {TransportConfig} from '@shared/models/transport-config.model';
import {OrderIdentifiers} from './order.model';

export enum ShipmentStatus {
  AWAITING_CARGO_ALLOCATION = 'AWAITING_CARGO_ALLOCATION',
  CARGO_ALLOCATED = 'CARGO_ALLOCATED',
  COST_PENDING = 'COST_PENDING',
  COST_ASSIGNED = 'COST_ASSIGNED',
  COST_PROVIDED = 'COST_PROVIDED',
  COST_NOT_FOUND = 'COST_NOT_FOUND',
  COST_APPROVAL_PENDING = 'COST_APPROVAL_PENDING',
  COST_APPROVAL_ASSIGNED = 'COST_APPROVAL_ASSIGNED',
  COST_APPROVED = 'COST_APPROVED',
  COST_REJECTED = 'COST_REJECTED',
  BOOKING_PENDING = 'BOOKING_PENDING',
  BOOKING_ASSIGNED = 'BOOKING_ASSIGNED',
  BOOKED = 'BOOKED',
  COMPLIANCE_PENDING = 'COMPLIANCE_PENDING',
  COMPLIANCE_ASSIGNED = 'COMPLIANCE_ASSIGNED',
  COMPLIANCE_APPROVED = 'COMPLIANCE_APPROVED',
  AWAITING_COLLECTION = 'AWAITING_COLLECTION',
  COLLECTED = 'COLLECTED',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED'
}

export enum ModeOfTransport {
  RAIL = 'RAIL',
  ROAD = 'ROAD',
  SEA = 'SEA',
  AIR = 'AIR',
  COURIER = 'COURIER'
}

export enum CostSplittingStrategy {
  REQUIRED_AREA = 'REQUIRED_AREA'
}

export type ShipmentForm = Omit<Shipment, 'id' | 'status' | 'cargoValue' | 'organisation' | 'financeOwner' | 'transportConfig'> & {
  organisation: string;
  transportConfigId: string;
  financeOwnerId: string;
};

export type ShipmentDto = Omit<
  Shipment,
  'expectedCollectionDate' | 'expectedDeliveryDate' | 'estimatedCollectionDate' | 'estimatedDeliveryDate' | 'actualCollectionDate' | 'actualDeliveryDate' | 'createdDate' | 'reopenDate'
> & {
  expectedCollectionDate: string;
  expectedDeliveryDate: string;
  estimatedCollectionDate: string;
  estimatedDeliveryDate: string;
  actualCollectionDate: string;
  actualDeliveryDate: string;
  createdDate: string;
  reopenDate: string;
};

export type ShipmentBuCostSplitDto = ShipmentBuCostSplit;

export interface ShipmentBuCostSplit {
  id: string;
  buName: string;
  buErpId: string;
  cost: Cost;
}

export interface Shipment {
  id: string;
  organisation: Organisation;
  shipmentNumber: string;
  status: ShipmentStatus;
  collectionAddress: Address;
  collectionContactInformation: ContactInformation;
  deliveryAddress: Address;
  deliveryContactInformation: ContactInformation;
  incoterms: Incoterm;
  packageStructure: PackageStructure;
  expectedCollectionDate?: DateTime;
  expectedDeliveryDate?: DateTime;
  estimatedCollectionDate?: DateTime;
  estimatedDeliveryDate?: DateTime;
  actualCollectionDate?: DateTime;
  actualDeliveryDate?: DateTime;
  actionByDate: DateTime;
  cargoValue: Cost;
  specialInstructions: string;
  measurementSystem: MeasurementSystem;
  estimatedCostApproved: boolean;
  bookingRequired: boolean;
  complianceRequired: boolean;
  createdDate: DateTime;
  createdBy: User;
  tags: Array<string>;
  validStatusChanges: Array<string>;
  deliveryWarehouseId: string;
  collectionWarehouseId: string;
  bookingReference: string;
  modeOfTransport: ModeOfTransport;
  freightForwarderId: string;
  estimatedCost?: Cost;
  invoicedCost?: Cost;
  costSplittingStrategy: CostSplittingStrategy;
  costingRequired: boolean;
  costApprovalRequired: boolean;
  documentOnly: boolean;
  financeOwner: User;
  trailerVehicleRegistration?: string;
  sealNumber?: string;
  billOfLadingNumber?: string;
  houseBillOfLadingNumber?: string;
  airwayBillNumber?: string;
  houseAirwayBillNumber?: string;
  containerNumber?: string;
  transportConfig: TransportConfig;
  transportConfigQuantity?: number;
  recurringShipmentId?: string;
  reopenDate?: DateTime;
  orders?: OrderIdentifiers;
}
