import {DateTime} from 'luxon';
import {Address, ContactInformation} from './address.model';
import {Cost} from './cost.model';
import {Incoterm} from './incoterms.model';
import {MeasurementSystem} from './measurement-system.model';
import {PackageStructure} from './package.model';
import {Shipment} from './shipment.model';
import {Organisation, User} from './user.model';
import {LegalEntity} from '@shared/models/legal-entity.model';

export enum OrderIntent {
  SHIPPING = 'SHIPPING',
  QUOTE_ONLY = 'QUOTE_ONLY',
  DOCUMENT_ONLY = 'DOCUMENT_ONLY',
  CUSTOMS = 'CUSTOMS'
}

export enum OrderType {
  B2B = 'B2B',
  IWT = 'IWT',
  PROCUREMENT = 'PROCUREMENT',
  MISC = 'MISC'
}

export enum OrderStatus {
  SCHEDULED = 'SCHEDULED',
  UNSCHEDULED = 'UNSCHEDULED',
  QUOTE_PENDING = 'QUOTE_PENDING',
  QUOTE_ASSIGNED = 'QUOTE_ASSIGNED',
  QUOTED = 'QUOTED',
  DOCUMENT_ONLY_PENDING = 'DOCUMENT_ONLY_PENDING',
  DOCUMENT_ONLY_ASSIGNED = 'DOCUMENT_ONLY_ASSIGNED',
  DOCUMENT_ONLY_COMPLETED = 'DOCUMENT_ONLY_COMPLETED',
  CUSTOMS_PENDING = 'CUSTOMS_PENDING',
  CUSTOMS_ASSIGNED = 'CUSTOMS_ASSIGNED',
  CUSTOMS_COMPLETED = 'CUSTOMS_COMPLETED',
  CANCELLED = 'CANCELLED'
}

export enum OrderRejectionReason {
  EXCEED_TRANSPORT_LIMITATIONS = 'EXCEED_TRANSPORT_LIMITATIONS',
  INCORRECT_PACKING = 'INCORRECT_PACKING',
  PACKING_LIST_MISSING = 'PACKING_LIST_MISSING',
  PACKING_LIST_INCORRECT = 'PACKING_LIST_INCORRECT',
  MISSING_UNIT_VALUES = 'MISSING_UNIT_VALUES',
  INCORRECT_INCOTERMS = 'INCORRECT_INCOTERMS',
  INCORRECT_COLLECTION_OR_DELIVERY_ADDRESS = 'INCORRECT_COLLECTION_OR_DELIVERY_ADDRESS',
  MISSING_CONTACT_DETAILS = 'MISSING_CONTACT_DETAILS',
  MISSING_COLLECTION_OR_DELIVERY_REFERENCE = 'MISSING_COLLECTION_OR_DELIVERY_REFERENCE',
  CANCELLATION_REQUESTED_BY_CUSTOMER = 'CANCELLATION_REQUESTED_BY_CUSTOMER',
  CANCELLATION_REQUESTED_BY_THG = 'CANCELLATION_REQUESTED_BY_THG',
  INCORRECT_IWT_TRANSPORT_MODE = 'INCORRECT_IWT_TRANSPORT_MODE'
}

export type OrderForm = Omit<Order, 'actionByDate' | 'id' | 'status' | 'taskAssignedTo' | 'taskCompletedBy' | 'quote'>;

export type OrderDto = Omit<
  Order,
  'actionByDate' | 'requestedCollectionDate' | 'requestedDeliveryDate' | 'createdDate' | 'rejectionDate' | 'reopenDate'
> & {
  requestedCollectionDate: string;
  requestedDeliveryDate: string;
  actionByDate: string;
  createdDate: string;
  rejectionDate: string;
  reopenDate?: string;
};

export interface Order {
  id: string;
  shipmentId: string | null,
  orderNumber: string;
  intent: OrderIntent;
  status: OrderStatus;
  reference: string;
  type: OrderType;
  warehouseCollection: boolean;
  warehouseDelivery: boolean;
  collectionAddress: Address;
  collectionContactInformation: ContactInformation;
  deliveryAddress: Address;
  deliveryContactInformation: ContactInformation;
  incoterms: Incoterm;
  packageStructure: PackageStructure;
  requestedCollectionDate: DateTime;
  requestedDeliveryDate: DateTime;
  actionByDate: DateTime;
  cargoValue: Cost;
  quote?: Cost;
  estimatedCost?: Cost;
  invoicedCost?: Cost;
  specialInstructions: string;
  measurementSystem: MeasurementSystem;
  stackable: boolean;
  tailLiftRequired: boolean;
  refrigerated: boolean;
  hazardous: boolean;
  createdDate: DateTime;
  createdBy: User;
  ranking: number;
  rankable: boolean;
  tags: Array<string>;
  collectionWarehouseId?: string;
  deliveryWarehouseId?: string;
  organisation: Organisation;
  collectionReference: string;
  deliveryReference: string;
  shipmentMinView?: Pick<Shipment, 'id' | 'status' | 'shipmentNumber' | 'createdBy'>;
  buyerLegalEntity: LegalEntity;
  sellerLegalEntity: LegalEntity;
  externalRequesterEmail?: string;
  taskCompletedBy?: User;
  taskAssignedTo?: User;
  businessUnits: Array<string>;
  rejectionMessage?: string;
  rejectedBy?: User;
  rejectionDate?: DateTime;
  reopenDate?: DateTime;
  collectionVAT?: string;
  deliveryVAT?: string;
  collectionEORI?: string;
  deliveryEORI?: string;
  purchaseOrderNumber?: string;
}

export interface OrderAllocation {
  order: Order,
  shipment: Shipment
}

export interface OrderIdentifier {
  orderNumber: string;
  reference: string;
}

// Required due to the restricted nature of Spring Data JDBC converters
export interface OrderIdentifiers {
  identifiers: Array<OrderIdentifier>;
}
