<app-datatable
  [tableColumns]="tableColumns"
  [data]="documents.data"
  [rowCount]="(filterTableHeaderService.pagination | async)?.limit || 10"
  [totalRecords]="documents.total"
  (pageChangeEvent)="changePagination($event)"
  [loading]="loading"
>
  <ng-template [appTableHeader]="tableColumns" let-columns>
    <tr>
      <th *ngFor="let column of columns" scope="col">{{column.header}}</th>
    </tr>
  </ng-template>

  <ng-template [appTableRow]="documents.data" let-document>
    <tr>
      <td><lucide-icon name="truck" class="font-semibold tracking-tight text-blue-500" *ngIf="document.freightForwarderName" [pTooltip]="'Document auto-imported from ' + document.freightForwarderName" tooltipPosition="top"/></td>
      <td>
        <a
          class="font-semibold tracking-tight text-blue-500 hover:underline"
          [routerLink]="document.shipmentNumber ? '/dashboard/shipment/' + document.shipmentNumber : '/dashboard/order/' + document.orderNumber"
        >
          {{ document.shipmentNumber ?? document.orderReference}}
        </a>
      </td>
      <td>{{ document.name}}</td>
      <td>{{ document.type}}</td>
      <td>{{ document.freightForwarderName ?? document.createdBy.name}}</td>
      <td>{{ document.createdDate | dateFormat}}</td>
      <td>
        <div class="flex flex-row">
          <p-button styleClass="p-button p-button-danger"
                    [text]="true"
                    (click)="removeDocument(document)"
                    *ngIf="!document.freightForwarderName"
          >
            <lucide-icon name="trash"></lucide-icon>
          </p-button>
          <p-button styleClass="p-button p-button-success"
                    [text]="true"
                    (click)="downloadDocument(document)"
          >
            <lucide-icon name="Download"></lucide-icon>
          </p-button>
        </div>
      </td>
    </tr>
  </ng-template>
</app-datatable>
