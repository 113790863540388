import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {map, Observable} from 'rxjs';
import {DropdownOption} from '../../models/utility/option.model';
import {
  CostSplittingStrategy,
  ModeOfTransport,
  Shipment, ShipmentBuCostSplit, ShipmentBuCostSplitDto,
  ShipmentDto,
  ShipmentForm,
  ShipmentStatus
} from '../../models/shipment.model';
import {StatusStyle} from '../../models/status.model';
import {Filter} from '@shared/models/utility/filter';
import {Page} from '@shared/models/utility/page.model';
import {HttpParamBuilder} from '@core/utils/http-param-builder';
import {convertArrayOfFiltersToObject} from '@core/utils/filters';
import {AutoApprovalResult} from '@shared/models/autoapproval.model';
import {OrderProduct, OrderProductDto} from '@shared/models/order-product.model';
import {Event, EventDto} from '@shared/models/shipment-event.model';
import {ShipmentListOrdering} from '@modules/dashboard/modules/shipment/models/shipment-filters';
import {BookingReferenceValidation} from '@shared/models/booking-reference-validation.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  private url = '/api/shipment';

  constructor(private _httpClient: HttpClient) {
  }

  public createShipment(shipmentForm: ShipmentForm, orderId?: string): Observable<Shipment> {
    const apiData = {...shipmentForm, orderId};
    return this._httpClient.post<Shipment>(this.url, apiData);
  }

  public updateShipment(id: string, shipmentForm: ShipmentForm): Observable<Shipment> {
    return this._httpClient.put<Shipment>(`${this.url}/${id}`, shipmentForm);
  }

  public updateShipmentStatus(id: string, status: ShipmentStatus): Observable<void> {
    return this._httpClient.put<void>(`${this.url}/${id}/status`, {status});
  }

  public updateShipmentOwner(shipmentId: string, userId: string): Observable<void> {
    return this._httpClient.put<void>(`${this.url}/${shipmentId}/shipment-owner/${userId}`, {});
  }

  public cancelShipment(id: string): Observable<void> {
    return this._httpClient.delete<void>(`${this.url}/${id}`);
  }

  public cancelShipmentTasks(id: string): Observable<void> {
    return this._httpClient.delete<void>(`${this.url}/${id}/tasks`);
  }

  public reopenShipment(id: string): Observable<void> {
    return this._httpClient.put<void>(`${this.url}/${id}/reopen`, {});
  }

  public fetchAutoApprovalResult(id: string): Observable<AutoApprovalResult[]> {
    return this._httpClient.get<AutoApprovalResult[]>(`${this.url}/${id}/auto-approval`);
  }

  public fetchShipment(id: string): Observable<Shipment> {
    return this._httpClient.get<ShipmentDto>(`${this.url}/${id}`).pipe(map((dto) => ({
      ...dto,
      expectedCollectionDate: DateTime.fromISO(dto.expectedCollectionDate),
      expectedDeliveryDate: DateTime.fromISO(dto.expectedDeliveryDate),
      estimatedCollectionDate: dto.estimatedCollectionDate ? DateTime.fromISO(dto.estimatedCollectionDate) : undefined,
      estimatedDeliveryDate: dto.estimatedDeliveryDate ? DateTime.fromISO(dto.estimatedDeliveryDate) : undefined,
      actualCollectionDate: dto.actualCollectionDate ? DateTime.fromISO(dto.actualCollectionDate) : undefined,
      actualDeliveryDate: dto.actualDeliveryDate ? DateTime.fromISO(dto.actualDeliveryDate) : undefined,
      createdDate: DateTime.fromISO(dto.createdDate),
      reopenDate: dto.reopenDate ? DateTime.fromISO(dto.reopenDate) : undefined
    })));
  }

  public fetchShipmentEvents(id: string): Observable<Array<Event>> {
    return this._httpClient.get<Array<EventDto>>(`${this.url}/${id}/timeline`).pipe(
      map((events) => events.map((event) => ({
        ...event,
        event: JSON.parse(event.event.json),
        createdDate: DateTime.fromISO(event.createdDate)
      }))));
  }

  public fetchLastShipmentEventByType(id: string, eventType: string[]): Observable<Event> {
    return this._httpClient.get<EventDto>(`${this.url}/${id}/timeline/latest?eventTypes=${eventType}`).pipe(
      map((event) => ({
        ...event,
        event: JSON.parse(event.event.json),
        createdDate: DateTime.fromISO(event.createdDate)
      })));
  }

  public fetchCostSplittingStrategyOptions(): Array<DropdownOption<CostSplittingStrategy>> {
    return [
      {label: 'Required Area', value: CostSplittingStrategy.REQUIRED_AREA}
    ];
  }

  public fetchShipmentBuCostSplittingStrategy(shipmentId: string, offset: number, size: number): Observable<Page<ShipmentBuCostSplit>> {
    const params: HttpParams = HttpParamBuilder({offset, size});
    return this._httpClient.get<Page<ShipmentBuCostSplitDto>>(`${this.url}/${shipmentId}/bu-cost-split`, {params});
  }

  public fetchShipmentProducts(id: string, offset: number, size: number): Observable<Page<OrderProduct>> {
    const params: HttpParams = HttpParamBuilder({offset, size});
    return this._httpClient.get<Page<OrderProductDto>>(`${this.url}/${id}/products`, {params}).pipe(map((dto: Page<OrderProductDto>) => ({
      ...dto,
      data: dto.data.map((orderProduct) => ({
        ...orderProduct,
        expiryDate: orderProduct.expiryDate ? DateTime.fromISO(orderProduct.expiryDate) : undefined
      }))
    })));
  }

  public fetchModeOfTransportOptions(): Array<DropdownOption<ModeOfTransport>> {
    return [
      {label: 'Air', value: ModeOfTransport.AIR},
      {label: 'Sea', value: ModeOfTransport.SEA},
      {label: 'Rail', value: ModeOfTransport.RAIL},
      {label: 'Road', value: ModeOfTransport.ROAD},
      {label: 'Courier', value: ModeOfTransport.COURIER}
    ];
  }

  public convertToStatusDropdown(statuses: Array<string>): Array<DropdownOption<ShipmentStatus>> {
    return this.fetchShipmentStatus().filter((i) => statuses.includes(i.value));
  }

  public fetchShipmentStatus(): Array<DropdownOption<ShipmentStatus>> {
    return [
      {label: 'Completed', value: ShipmentStatus.COMPLETED},
      {label: 'Awaiting Cargo Allocation', value: ShipmentStatus.AWAITING_CARGO_ALLOCATION},
      {label: 'Awaiting Collection', value: ShipmentStatus.AWAITING_COLLECTION},
      {label: 'Booked', value: ShipmentStatus.BOOKED},
      {label: 'Booking Pending', value: ShipmentStatus.BOOKING_PENDING},
      {label: 'Booking Assigned', value: ShipmentStatus.BOOKING_ASSIGNED},
      {label: 'Compliance Assigned', value: ShipmentStatus.COMPLIANCE_ASSIGNED},
      {label: 'Compliance Pending', value: ShipmentStatus.COMPLIANCE_PENDING},
      {label: 'Compliance Approved', value: ShipmentStatus.COMPLIANCE_APPROVED},
      {label: 'Collected', value: ShipmentStatus.COLLECTED},
      {label: 'Cancelled', value: ShipmentStatus.CANCELLED},
      {label: 'Cargo Allocated', value: ShipmentStatus.CARGO_ALLOCATED},
      {label: 'Closed', value: ShipmentStatus.CLOSED},
      {label: 'Cost Approval Assigned', value: ShipmentStatus.COST_APPROVAL_ASSIGNED},
      {label: 'Cost Approval Pending', value: ShipmentStatus.COST_APPROVAL_PENDING},
      {label: 'Cost Approved', value: ShipmentStatus.COST_APPROVED},
      {label: 'Cost Assigned', value: ShipmentStatus.COST_ASSIGNED},
      {label: 'Cost Pending', value: ShipmentStatus.COST_PENDING},
      {label: 'Cost Provided', value: ShipmentStatus.COST_PROVIDED},
      {label: 'Cost Approved', value: ShipmentStatus.COST_APPROVED},
      {label: 'Cost Rejected', value: ShipmentStatus.COST_REJECTED},
      {label: 'Delivered', value: ShipmentStatus.DELIVERED}
    ];
  }

  public fetchShipmentStatusStyling(status: ShipmentStatus): StatusStyle {
    const map = new Map<ShipmentStatus, StatusStyle>([
      [ShipmentStatus.CANCELLED, {name: 'Cancelled', severity: 'danger'}],
      [ShipmentStatus.AWAITING_CARGO_ALLOCATION, {name: 'Awaiting cargo allocation', severity: 'warning'}],
      [ShipmentStatus.CARGO_ALLOCATED, {name: 'Cargo allocated', severity: 'info'}],
      [ShipmentStatus.COST_PENDING, {name: 'Cost pending', severity: 'info'}],
      [ShipmentStatus.COST_ASSIGNED, {name: 'Cost assigned', severity: 'info'}],
      [ShipmentStatus.COST_PROVIDED, {name: 'Cost provided', severity: 'info'}],
      [ShipmentStatus.COST_NOT_FOUND, {name: 'Cost not found', severity: 'danger'}],
      [ShipmentStatus.COST_APPROVAL_PENDING, {name: 'Cost approval pending', severity: 'info'}],
      [ShipmentStatus.COST_APPROVAL_ASSIGNED, {name: 'Cost approval assigned', severity: 'info'}],
      [ShipmentStatus.COST_APPROVED, {name: 'Cost approved', severity: 'info'}],
      [ShipmentStatus.COST_REJECTED, {name: 'Cost rejected', severity: 'danger'}],
      [ShipmentStatus.BOOKING_PENDING, {name: 'Booking pending', severity: 'info'}],
      [ShipmentStatus.BOOKING_ASSIGNED, {name: 'Booking assigned', severity: 'info'}],
      [ShipmentStatus.BOOKED, {name: 'Booked', severity: 'info'}],
      [ShipmentStatus.COMPLIANCE_PENDING, {name: 'Compliance pending', severity: 'info'}],
      [ShipmentStatus.COMPLIANCE_ASSIGNED, {name: 'Compliance assigned', severity: 'info'}],
      [ShipmentStatus.COMPLIANCE_APPROVED, {name: 'Compliance approved', severity: 'info'}],
      [ShipmentStatus.AWAITING_COLLECTION, {name: 'Awaiting collection', severity: 'info'}],
      [ShipmentStatus.COLLECTED, {name: 'Collected', severity: 'info'}],
      [ShipmentStatus.DELIVERED, {name: 'Delivered', severity: 'info'}],
      [ShipmentStatus.COMPLETED, {name: 'Completed', severity: 'info'}],
      [ShipmentStatus.CLOSED, {name: 'Closed', severity: 'success'}]
    ]);

    return map.has(status) ? map.get(status)! : {name: status, severity: 'info'};
  }

  public fetchShipmentList(
    offset: number,
    size: number,
    filters: Array<Filter>,
    search: string | null
  ): Observable<Page<Shipment>> {
    search = (search ? search.trim() : null);
    const params: HttpParams = HttpParamBuilder({offset, size, ...convertArrayOfFiltersToObject(filters), search});
    return this._httpClient.get<Page<ShipmentDto>>(`${this.url}/list`, {params}).pipe(map((dto: Page<ShipmentDto>) => ({
      ...dto,
      data: dto.data.map((shipmentDto) => ({
        ...shipmentDto,
        expectedCollectionDate: DateTime.fromISO(shipmentDto.expectedCollectionDate),
        expectedDeliveryDate: DateTime.fromISO(shipmentDto.expectedDeliveryDate),
        estimatedCollectionDate: DateTime.fromISO(shipmentDto.estimatedCollectionDate),
        estimatedDeliveryDate: DateTime.fromISO(shipmentDto.estimatedDeliveryDate),
        actualCollectionDate: shipmentDto.actualCollectionDate ?
          DateTime.fromISO(shipmentDto.actualCollectionDate) :
          undefined,
        actualDeliveryDate: shipmentDto.actualDeliveryDate ?
          DateTime.fromISO(shipmentDto.actualDeliveryDate) :
          undefined,
        createdDate: DateTime.fromISO(shipmentDto.createdDate),
        reopenDate: shipmentDto.reopenDate ? DateTime.fromISO(shipmentDto.reopenDate) : undefined
      }))
    })));
  }

  public fetchShipmentListOrderingOptions(): Array<DropdownOption<ShipmentListOrdering>> {
    return [
      {label: 'Created Date', value: ShipmentListOrdering.CREATED_DATE},
      {label: 'Updated Date', value: ShipmentListOrdering.UPDATED_DATE}
    ];
  }

  public fetchShipmentListByRecurringShipmentId(
    offset: number,
    size: number,
    recurringShipmentId: number
  ): Observable<Page<Shipment>> {
    const params: HttpParams = HttpParamBuilder({offset, size});
    return this._httpClient.get<Page<ShipmentDto>>(`${this.url}/list/${recurringShipmentId}`, {params}).pipe(map((dto: Page<ShipmentDto>) => ({
      ...dto,
      data: dto.data.map((shipmentDto) => ({
        ...shipmentDto,
        expectedCollectionDate: DateTime.fromISO(shipmentDto.expectedCollectionDate),
        expectedDeliveryDate: DateTime.fromISO(shipmentDto.expectedDeliveryDate),
        estimatedCollectionDate: DateTime.fromISO(shipmentDto.estimatedCollectionDate),
        estimatedDeliveryDate: DateTime.fromISO(shipmentDto.estimatedDeliveryDate),
        actualCollectionDate: shipmentDto.actualCollectionDate ?
          DateTime.fromISO(shipmentDto.actualCollectionDate) :
          undefined,
        actualDeliveryDate: shipmentDto.actualDeliveryDate ?
          DateTime.fromISO(shipmentDto.actualDeliveryDate) :
          undefined,
        createdDate: DateTime.fromISO(shipmentDto.createdDate),
        reopenDate: shipmentDto.reopenDate ? DateTime.fromISO(shipmentDto.reopenDate) : undefined
      }))
    })));
  }

  public validateShipmentBookingReference(bookingReference: string, freightForwarderId: string): Observable<BookingReferenceValidation> {
    const params: HttpParams = HttpParamBuilder({bookingReference, freightForwarderId});
    return this._httpClient.get<BookingReferenceValidation>(`${this.url}/validate`, {params});
  }

}
