import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-label-with-info',
  templateUrl: './label-with-info.component.html'
})
export class LabelWithInfoComponent {

  @Input({required: true}) fieldName!: string;
  @Input({required: true}) fieldLabel!: string;
  @Input({required: false}) fieldDescription?: string;
  descriptionModalVisible!: boolean;

  closeModal(): void {
    this.descriptionModalVisible = false;
  }

  handleKeydown(event: KeyboardEvent): void {
    event.key === 'Enter' && (this.descriptionModalVisible = !this.descriptionModalVisible);
  }

  getModalHeader(): string {
    return this.fieldName.replace('*', '');
  }

}