import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PrimeNgTableColumn} from '@shared/models/utility/prime-ng-helpers';
import {OrderProduct} from '@shared/models/order-product.model';
import {FilterTableHeaderService} from '@shared/services/filter-table-header/filter-table-header.service';
import {TableLazyLoadEvent} from 'primeng/table/table.interface';
import {createEmptyPage, Page} from '@shared/models/utility/page.model';
import {EntityDocument} from '@shared/models/document.model';

interface TableHeaders extends OrderProduct {
  origin: string;
  document_title: string;
  document_type: string;
  uploaded_by: string;
  uploaded_when: string;
  freight_forwarder_document: string;
  action: string;
}

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html'
})
export class DocumentTableComponent {

  @Input({required: true}) documents: Page<EntityDocument> = createEmptyPage();
  @Input({required: true}) loading = false;
  @Output() documentDeleteEvent = new EventEmitter<EntityDocument>();
  @Output() documentDownloadEvent = new EventEmitter<EntityDocument>();

  tableColumns: Array<PrimeNgTableColumn<TableHeaders>> = [
    {field: 'freight_forwarder_document', header: ''},
    {field: 'origin', header: 'Origin'},
    {field: 'document_title', header: 'Document title'},
    {field: 'document_type', header: 'Document type'},
    {field: 'uploaded_by', header: 'Uploaded by'},
    {field: 'uploaded_when', header: 'Uploaded when'},
    {field: 'action', header: 'Action'}
  ];

  constructor(
    public filterTableHeaderService: FilterTableHeaderService<Headers>
  ) {
  }

  public changePagination(value: TableLazyLoadEvent): void {
    this.filterTableHeaderService.pagination = {offset: value.first ?? 0, limit: value.rows ?? 10};
  }

  public removeDocument(document: EntityDocument): void {
    this.documentDeleteEvent.emit(document);
  }

  public downloadDocument(document: EntityDocument): void {
    this.documentDownloadEvent.emit(document);
  }

}
