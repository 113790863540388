import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DropdownOption} from '@shared/models/utility/option.model';
import {Subject, Observable, startWith} from 'rxjs';
import {LegalEntity} from '@shared/models/legal-entity.model';
import {LegalEntityService} from '@shared/services/legal-entity/legal-entity.service';

@Component({
  selector: 'app-legal-entity-selector',
  templateUrl: './legal-entity-selector.component.html'
})
export class LegalEntitySelectorComponent {

  @Input({required: true}) prefix!: string;
  @Input() initialDisplayLegalEntity?: LegalEntity;

  search$: Subject<string> = new Subject<string>();
  @Output() selectedLegalEntity: EventEmitter<LegalEntity> = new EventEmitter<LegalEntity>();

  legalEntityOptions$: Observable<Array<DropdownOption<LegalEntity>>>;

  constructor(private legalEntityService: LegalEntityService) {
    this.legalEntityOptions$ = this.legalEntityService.legalEntitiesAsOptions().pipe(startWith([]));
  }

  onLegalEntityFilter(filter: string): void {
    if(filter?.length > 2) {
      this.search$.next(filter);
    }
  }

  onSelectionChange(legalEntity: DropdownOption<LegalEntity>) {
    this.selectedLegalEntity.emit(legalEntity.value);
  }

}
