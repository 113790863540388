import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, shareReplay, switchMap} from 'rxjs';
import {LegalEntity} from '@shared/models/legal-entity.model';
import {DropdownOption} from '@shared/models/utility/option.model';
import {Address} from '@shared/models/address.model';

@Injectable({
  providedIn: 'root'
})
export class LegalEntityService {

  private url = '/api/legal-entity';

  private readonly legalEntities$: Observable<Array<LegalEntity>>;

  constructor(private _httpClient: HttpClient) {
    this.legalEntities$ = this._httpClient.get<Array<LegalEntity>>(this.url).pipe(shareReplay());
  }

  get legalEntities(): Observable<Array<LegalEntity>> {
    return this.legalEntities$;
  }

  public legalEntitiesAsOptions(): Observable<Array<DropdownOption<LegalEntity>>> {
    return this.legalEntities.pipe(map((array) => array.map((savedAddress) => this.legalEntityToDropdownOption(savedAddress))));
  }

  public legalEntityToDropdownOption(legalEntity: LegalEntity): DropdownOption<LegalEntity> {
    return {label: this.getLegalEntityLabel(legalEntity), value: legalEntity};
  }

  public getLegalEntityLabel(legalEntity: LegalEntity): string {
    return `${legalEntity.erpId}, ${legalEntity.name}, ${Object.values(legalEntity.address).filter(Boolean)
      .join(', ')}`;
  }

}
