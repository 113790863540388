import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {LucideAngularModule} from 'lucide-angular';
import {InputTextModule} from 'primeng/inputtext';
import {InputNumberModule} from 'primeng/inputnumber';
import {MultiSelectModule} from 'primeng/multiselect';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddressFormComponent} from './forms/address-form/address-form.component';
import {PackagingFormComponent} from './forms/packaging-form/packaging-form.component';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import {RouterModule} from '@angular/router';
import {SidebarModule} from 'primeng/sidebar';
import {TagModule} from 'primeng/tag';
import {PipesModule} from '@shared/pipes/pipes.module';
import {DirectivesModule} from '@shared/directives/directives.module';
import {FormFieldComponent} from '@shared/components/form-field/form-field.component';
import {
  ShipmentDisplayCardComponent
} from '@shared/components/shipment/shipment-display-card/shipment-display-card.component';
import {
  ShipmentFiltersSidebarComponent
} from '@shared/components/shipment/shipment-filters-sidebar/shipment-filters-sidebar.component';
import {
  ShipmentSelectionHeaderComponent
} from '@shared/components/shipment/shipment-selection-header/shipment-selection-header.component';
import {
  ShipmentSelectionRowComponent
} from '@shared/components/shipment/shipment-selection-row/shipment-selection-row.component';
import {ShipmentSelectorComponent} from '@shared/components/shipment/shipment-selector/shipment-selector.component';
import {
  ShipmentStatusTagComponent
} from '@shared/components/shipment/shipment-status-tag/shipment-status-tag.component';
import {DatatableComponent} from '@shared/components/table/datatable/datatable.component';
import {NoDataHeaderComponent} from '@shared/components/table/no-data-header/no-data-header.component';
import {NoDataRowComponent} from '@shared/components/table/no-data-row/no-data-row.component';
import {ToastComponent} from '@shared/components/toasts/toast/toast.component';
import {BannerComponent} from '@shared/components/ui/banner/banner.component';
import {CardComponent} from '@shared/components/ui/card/card.component';
import {FilterTableHeaderComponent} from '@shared/components/ui/filter-table-header/filter-table-header.component';
import {GLOBAL_ICONS} from '@shared/global-icons';
import {SuccessToastComponent} from './components/toasts/success-toast/success-toast.component';
import {
  CustomToastWrapperComponent
} from '@shared/components/toasts/custom-toast-wrapper/custom-toast-wrapper.component';
import {ErrorToastComponent} from './components/toasts/error-toast/error-toast.component';
import {WarningToastComponent} from './components/toasts/warning-toast/warning-toast.component';
import {InfoToastComponent} from './components/toasts/info-toast/info-toast.component';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {DialogModule} from 'primeng/dialog';
import {WarehouseAddressFormComponent} from './forms/warehouse-address-form/warehouse-address-form.component';
import {AddressCardComponent} from './components/address-card/address-card.component';
import {PackagingInformationComponent} from '@shared/components/packaging-information/packaging-information.component';
import {OrderDisplayCardComponent} from './components/order/order-display-card/order-display-card.component';
import {OrderStatusTagComponent} from './components/order/order-status-tag/order-status-tag.component';
import {GenericTableHeaderComponent} from './components/table/generic-table-header/generic-table-header.component';
import {GenericTableRowComponent} from './components/table/generic-table-row/generic-table-row.component';
import {DocumentComponent} from './components/document-dialog/document-dialog.component';
import {FileUploadModule} from 'primeng/fileupload';
import {DocumentTableComponent} from './components/document-table/document-table.component';
import {UserFormComponent} from './forms/user-form/user-form.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {ContactInfoFormComponent} from './forms/contact-info-form/contact-info-form.component';
import {SavedAddressSelectorComponent} from './components/saved-address-selector/saved-address-selector.component';
import {
  ShipmentTaskFiltersSidebarComponent
} from '@shared/components/shipment/shipment-task-filters-sidebar/shipment-task-filters-sidebar.component';
import {TaskUptimeComponent} from './components/task-uptime/task-uptime.component';
import {LegalEntityComponent} from './forms/legal-entity/legal-entity.component';
import {AlertComponent} from './components/alert/alert.component';
import {FilterChipComponent} from './components/filter-chip/filter-chip.component';
import {MonoIntervalComponent} from './components/mono-interval/mono-interval.component';
import {LabelWithInfoComponent} from './components/label-with-info/label-with-info.component';
import {LegalEntitySelectorComponent} from './components/legal-entity-selector/legal-entity-selector.component';

const COMPONENTS = [
  FormFieldComponent,
  ShipmentDisplayCardComponent,
  ShipmentFiltersSidebarComponent,
  ShipmentSelectionHeaderComponent,
  ShipmentSelectionRowComponent,
  ShipmentSelectorComponent,
  ShipmentStatusTagComponent,
  ShipmentTaskFiltersSidebarComponent,
  DatatableComponent,
  NoDataHeaderComponent,
  NoDataRowComponent,
  ToastComponent,
  BannerComponent,
  CardComponent,
  FilterTableHeaderComponent,
  PackagingInformationComponent,
  OrderDisplayCardComponent,
  ContactInfoFormComponent,
  OrderStatusTagComponent,
  SavedAddressSelectorComponent,
  LegalEntitySelectorComponent,
  GenericTableHeaderComponent,
  LoadingSpinnerComponent,
  TaskUptimeComponent,
  AlertComponent,
  LabelWithInfoComponent
];

@NgModule({
  declarations: [
    AddressFormComponent,
    PackagingFormComponent,
    SuccessToastComponent,
    ...COMPONENTS,
    CustomToastWrapperComponent,
    ErrorToastComponent,
    WarningToastComponent,
    InfoToastComponent,
    ConfirmationDialogComponent,
    WarehouseAddressFormComponent,
    AddressCardComponent,
    GenericTableRowComponent,
    DocumentComponent,
    DocumentTableComponent,
    UserFormComponent,
    SavedAddressSelectorComponent,
    LegalEntitySelectorComponent,
    FilterChipComponent,
    LegalEntityComponent,
    MonoIntervalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LucideAngularModule.pick(GLOBAL_ICONS),
    ButtonModule,
    InputTextModule,
    InputNumberModule,
    MultiSelectModule,
    DropdownModule,
    TableModule,
    InputSwitchModule,
    TagModule,
    RouterModule,
    SidebarModule,
    PipesModule,
    DirectivesModule,
    DialogModule,
    FileUploadModule,
    ProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LucideAngularModule,
    PipesModule,
    DirectivesModule,
    // Consider moving these in the future
    AddressFormComponent,
    UserFormComponent,
    PackagingFormComponent,
    ...COMPONENTS,
    WarehouseAddressFormComponent,
    GenericTableRowComponent,
    DocumentComponent,
    DocumentTableComponent,
    LegalEntityComponent,
    MonoIntervalComponent
  ],
  providers: []
})
export class SharedModule {
}
