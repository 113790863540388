<div class="relative bg-white rounded-lg shadow-sm border border-gray-200">
  <textarea
    [(ngModel)]="comment"
    (ngModelChange)="commentChange.emit($event)"
    [placeholder]="placeholder"
    class="w-full p-4 min-h-[100px] rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-purple-500/20 transition-shadow"
  ></textarea>
  <div class="p-3 flex justify-end gap-1">
    <p-button (onClick)="onCancel()"  label="Cancel" severity="secondary" class="text-sm font-medium rounded-md" />
    <p-button (onClick)="onSave()" label="Save" severity="success" class="text-sm font-medium rounded-md" [disabled]="comment===''"/>
  </div>
</div>
