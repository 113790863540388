<label for="legalEntity">{{ prefix }} Address</label>
<div class="mt-2">
  <p-dropdown
    *ngIf="legalEntityOptions$ | async as options"
    id="legalEntity"
    [autoDisplayFirst]="false"
    [options]="options"
    optionLabel="label"
    dataKey="value"
    [filter]="true"
    filterBy="label"
    (onFilter)="onLegalEntityFilter($event.filter)"
    (onChange)="onSelectionChange($event.value)"
    [autofocusFilter]="true"
    [placeholder]="initialDisplayLegalEntity ? (initialDisplayLegalEntity.address | address) : 'Select legal entity...'"
    [style]="{width: '100%'}"/>
</div>
