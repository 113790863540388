<div class="flex justify-between">
    <label for={{fieldLabel}}>{{fieldName}}</label>
    <i-lucide 
      name="info" 
      class="h-6 text-primary cursor-pointer" 
      *ngIf="fieldDescription" 
      (click)="descriptionModalVisible = !descriptionModalVisible"
      (keydown) = "handleKeydown($event)"      
      tabindex="0"/>
</div>
<p-dialog
  header={{getModalHeader()}}
  [modal]="true"
  [(visible)]="descriptionModalVisible"
  [style]="{ width: '30rem'}" 
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [closeOnEscape]="true"
  appendTo="body"
  >
  {{fieldDescription}}
</p-dialog>