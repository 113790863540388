import {Injectable} from '@angular/core';
import {forkJoin, map, Observable} from 'rxjs';
import {EntityDocument, EntityDocumentDto, DocumentUploadDto} from '@shared/models/document.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DateTime} from 'luxon';
import {HttpParamBuilder} from '@core/utils/http-param-builder';
import {Page} from '@shared/models/utility/page.model';
import {InvoiceConfig} from '@shared/models/invoice.model';
import {OrderRequiredDocument} from '@shared/models/order-required-document.model';

@Injectable({
  providedIn: 'root'
})
export class OrderDocumentService {

  constructor(private _httpClient: HttpClient) {}

  public fetchAllOrderDocuments(orderId: string, shipmentId: string | null, offset: number, size: number): Observable<Page<EntityDocument>> {
    const params: HttpParams = HttpParamBuilder({offset, size, shipmentId});
    return this._httpClient.get<Page<EntityDocumentDto>>(`/api/order/${orderId}/document`, {params}).pipe(map((dto: Page<EntityDocumentDto>) => ({
      ...dto,
      data: dto.data.map((document) => ({
        ...document,
        createdDate: DateTime.fromISO(document.createdDate)
      }))
    })));
  }

  public createOrderDocument(documentUploadDto: DocumentUploadDto, file: File, orderId: string): Observable<DocumentUploadDto> {
    const formData = new FormData();
    const documentBlob = new Blob([JSON.stringify(documentUploadDto)], {type: 'application/json'});
    formData.append('file', file);
    formData.append('documentDto', documentBlob);

    return this._httpClient.post<DocumentUploadDto>(`/api/order/${orderId}/document`, formData);
  }

  public createMultipleOrderDocuments(documentUploadDtos: DocumentUploadDto[], files: File[], orderId: string): Observable<DocumentUploadDto[]> {
    const requests: Observable<DocumentUploadDto>[] = documentUploadDtos.map((dto, index) => this.createOrderDocument(dto, files[index], orderId)
    );

    return forkJoin(requests);
  }

  public removeOrderDocument(documentId: string): Observable<void> {
    return this._httpClient.delete<void>(`/api/order/document/${documentId}`);
  }

  public downloadDocument(document: EntityDocument): Observable<Blob> {
    return this._httpClient.get<Blob>(`/api/order/document/${document.id}`, {
      responseType: 'blob' as 'json'
    });
  }

  public downloadOrderInvoice(orderId: string, config: InvoiceConfig): Observable<Blob> {
    const params: HttpParams = HttpParamBuilder(config);

    return this._httpClient.get<Blob>(`/api/order/${orderId}/invoice`, {
      params: params,
      responseType: 'blob' as 'json'
    });
  }

  public fetchRequiredDocs(orderId: string): Observable<Array<OrderRequiredDocument>> {
    return this._httpClient.get<Array<OrderRequiredDocument>>(`/api/order-required-document/${orderId}`);
  }

}
