import {Component, HostListener, Inject} from '@angular/core';
import {ThemeService} from '@core/services/theme.service';
import {filter} from "rxjs";
import {KeycloakEventType, KeycloakService} from "keycloak-angular";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  title = 'Freight';
  start = Date.now();

  constructor(
    @Inject(Router) private router: Router,
    @Inject(KeycloakService) private keycloak: KeycloakService,
    public themeService: ThemeService,
  ) {
    this.listenForSessionTimedOut();
  }

  getRefreshExpiryTime(): number {
    const expiry = this.keycloak.getKeycloakInstance().refreshTokenParsed?.exp;
    return expiry ? (expiry*1000) - Date.now() : 0;
  }

  listenForSessionTimedOut(): void {
    this.keycloak.keycloakEvents$.pipe(
      filter(event => event.type === KeycloakEventType.OnTokenExpired)
    ).subscribe(() => this.refreshToken())
  }

  forwardAndLogout(): void {
    this.router.navigate(['/session-timeout'],{queryParams: {lastVisited: this.router.url}})
      .then(() => this.keycloak.logout());
  }

  refreshToken(): void {
    let currentTime = Date.now();
    if (currentTime - this.start < this.getRefreshExpiryTime()) {
      this.keycloak.updateToken().catch(() => this.forwardAndLogout());
    } else {
      this.forwardAndLogout();
    }
  }

  @HostListener('click', ['$event'])
  updateRefreshExpiryTime(_event: MouseEvent): void {
    this.start = Date.now();
  }

}

